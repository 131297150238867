import Vue from 'vue'
import App from './App'
import { router } from './router'
import store from './store'
import { VueAxios } from './utils/request'
import VueLazyload from 'vue-lazyload'
import loadingIcon from '@/assets/icons/loading-icon.svg'
import loadingError from '@/assets/icons/loading-error.svg'
import vant from 'vant'
import 'vant/lib/index.css'
// import VConsole from 'vconsole'
import VueAliplayerV2 from 'vue-aliplayer-v2'
import VueCompositionAPI from '@vue/composition-api'
import VueLuckyCanvas from '@lucky-canvas/vue'
import PageTitle from '@/components/PageTitle'
Vue.component('PageTitle', PageTitle)
Vue.use(VueCompositionAPI)
Vue.use(VueLuckyCanvas)
Vue.use(vant)
Vue.use(VueAxios)
Vue.use(VueAliplayerV2)

Vue.use(VueLazyload, {
  loading: loadingIcon,
  error: loadingError
})

// new VConsole()
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
})
