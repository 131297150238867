import axios from 'axios'
import storage from 'store'
import { Toast } from 'vant'
import { VueAxios } from './axios'

console.log('VUE_APP_BASE_API', process.env.VUE_APP_BASE_API)
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'https://ruoyi.setworld.net',
  // 请求超时时间
  timeout: 60000
})

// 异常拦截处理器
const errorHandler = error => {
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  Toast.fail({
    message: message,
    duration: 5 * 1000
  })
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get('ANSWER_OPENID')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    // 'Bearer ' + 让每个请求携带自定义token 请根据实际情况自行修改
    config.headers['Authorization'] = token
    // config.headers['accessAccess-Token'] = token
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      const part = encodeURIComponent(propName) + '='
      if (value && typeof value !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            url += subPart + encodeURIComponent(value[key]) + '&'
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use(res => {
  // 请求rul
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  if (code !== 200) {
    Toast.fail({
      message: res.data.msg
    })
  } else {
    return res.data
  }
  return Promise.reject('服务器异常')
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export { installer as VueAxios, request as axios }
