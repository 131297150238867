import Vue from 'vue'
import Vuex from 'vuex'
import { axios } from './utils/request'
import storage from 'store'
import { Toast } from 'vant'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    openid: '',
    questions: {},
    result: {},
    gameStart: false
  },
  mutations: {
    SET_OPENID: (state, openid) => {
      state.openid = openid
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_QUESTIONS: (state, questions) => {
      state.questions = questions
    },
    SET_RESULT: (state, result) => {
      state.result = result
      storage.set('ANSWER_RESULT', result)
      storage.set('ANSWER_QUESTIONID', result.id)
    },
    UPDATE_GAME_STATUS: (state, gameStart) => {
      state.gameStart = gameStart
    }
  },
  actions: {
    Login({ commit }, code) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/auth/wxlogin',
          method: 'get',
          params: {
            code,
            shareId: storage.get('ANSWER_SHAREID'),
            date: storage.get('ANSWER_DATE')
          }
        })
          .then(res => {
            if (res.code == 200) {
              storage.set('ANSWER_OPENID', res.data.openid)
              commit('SET_OPENID', res.data.openid)
              commit('SET_USER', res.data)
              resolve(res.data)
            } else {
              reject(new Error())
              storage.remove('ANSWER_OPENID')
              Toast.fail(res.msg)
            }
          })
          .catch(error => {
            storage.remove('ANSWER_OPENID')
            reject(error)
          })
      })
    },
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/xt-exam/etUser/userinfo',
          method: 'get'
        })
          .then(res => {
            console.log('res', res)
            if (res.code == 200) {
              const user = res.data
              commit('SET_USER', user)
              commit('SET_OPENID', res.data.openid)
              resolve(res)
            } else {
              storage.remove('ANSWER_OPENID')
              reject()
            }
          })
          .catch(error => {
            storage.remove('ANSWER_OPENID')
            reject(error)
          })
      })
    }
  },
  getters: {}
})
