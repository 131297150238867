<template>
  <div
    id="app"
    :class="{ background: flag, transparent: $route.name !== 'index' }"
  >
    <router-view ref="view" />
  </div>
</template>
<script>
import storage from 'store'

export default {
  data() {
    return {
      flag: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    $route() {
      // this.getWxConfig()
    }
  },
  mounted() {
    const shareId = this.getUrlCode().shareId || storage.get('ANSWER_SHAREID')
    const date = this.getUrlCode().date || storage.get('ANSWER_DATE')
    storage.set('ANSWER_SHAREID', shareId)
    storage.set('ANSWER_DATE', date)
    this.init()

    setTimeout(() => (this.flag = true), 300)
  },
  methods: {
    callBack(type) {
      console.log(type)
      // this.$http({
      //   url: '/xt-exam/etUser/addShareLog',
      //   params: {
      //     type
      //   }
      // }).then(response => {
      //   console.log(response)
      // })
    },
    getWxConfig() {
      // const questionId = storage.get('ANSWER_QUESTIONID')
      const questionId = storage.get('ANSWER_OPENID')
      this.$http({
        url: '/xt-exam/etUser/getWxShareConfig',
        params: {
          url: window.location.href.split('#')[0]
        }
      }).then(response => {
        console.log(response)
        if (response.code == 200) {
          window.wx.config({
            // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            // debug: true,
            ...response.data,
            // 必填，需要使用的 JS 接口列表
            jsApiList: [
              'updateAppMessageShareData',
              'updateTimelineShareData'
              // 'onMenuShareTimeline',
              // 'onMenuShareAppMessage'
            ]
          })
          window.wx.ready(() => {
            window.wx.updateAppMessageShareData({
              title: '坚持预防为主 守护职业健康',
              desc:
                this.user.nickname +
                '邀请你一起参加2024年江苏省医疗卫生行业综合监管网络知识竞赛，答题赢红包！',
              link:
                'https://answer.microzl.com?shareId=' +
                questionId +
                '&date=' +
                new Date().getTime(),
              imgUrl:
                'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
              success: () => this.callBack('message')
            })
            window.wx.updateTimelineShareData({
              title: '坚持预防为主 守护职业健康',
              desc:
                this.user.nickname +
                '邀请你一起参加2024年江苏省医疗卫生行业综合监管网络知识竞赛，答题赢红包！',
              link:
                'https://answer.microzl.com?shareId=' +
                questionId +
                '&date=' +
                new Date().getTime(),
              imgUrl:
                'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
              success: () => this.callBack('timeline')
            })
            // window.wx.onMenuShareTimeline({
            //   title: '坚持预防为主 守护职业健康',
            //   link:
            //     'https://answer.microzl.com?shareId=' +
            //     questionId +
            //     '&date=' +
            //     new Date().getTime(),
            //   imgUrl:
            //     'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
            //   success: () => this.callBack('timeline')
            // })

            // window.wx.onMenuShareAppMessage({
            //   title: '坚持预防为主 守护职业健康',
            //   desc:
            //     this.user.nickname +
            //     '邀请你一起参加2024年江苏省医疗卫生行业综合监管网络知识竞赛，答题赢红包！',
            //   link:
            //     'https://answer.microzl.com?shareId=' +
            //     questionId +
            //     '&date=' +
            //     new Date().getTime(),
            //   imgUrl:
            //     'https://zhiye.nj12320.org:7780/exam-cdn/exam/exam_1701526446144_2023-12-02_448.png',
            //   success: () => this.callBack('message')
            // })
          })
        }
      })
    },
    init() {
      // od2fNt5J845FB27MmZ-hieWFew70
      let openid = storage.get('ANSWER_OPENID')
      // if (process.env.NODE_ENV === 'development') {
      //   openid = 'oXAHRsx4X0OVgM0yW7uFRdMmxsi8'
      // }
      // || 'od2fNt5J845FB27MmZ-hieWFew70'
      storage.set('ANSWER_OPENID', openid)

      // console.log('OPNID', !openid)

      if (!openid) {
        // 如果缓存localStorage中没有userInfo，则需用code去后台获取
        this.getCode()
      } else {
        this.$store.commit('SET_OPENID', openid)
        this.$store.dispatch('GetInfo').then(response => {
          if (!response.data.street || !response.data.userType) {
            // this.$router.push('/index/form')
          }
          this.getWxConfig()
        })
      }
    },
    getCode() {
      // 非静默授权，第一次有弹框
      this.code = ''
      const callback_url = process.env.VUE_APP_WEIXIN_CALLBACK_URL
      const appid = process.env.VUE_APP_WEIXIN_APP_ID
      console.log('callback_url', callback_url, appid)

      // 截取code
      this.code = this.getUrlCode().code
      // eslint-disable-next-line no-console
      console.log('this.code', this.code)
      if (this.code == null || this.code === '') {
        // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
          callback_url
        )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      } else {
        // 当code不等于空时，调用后端接口获取用户信息
        this.$store.dispatch('Login', this.code).then(data => {
          if (!data.street || !data.userType) {
            // this.$router.push('/index/form')
          }
          this.getWxConfig()
        })
      }
    },
    getUrlCode() {
      // 从url中获取code返回
      // 截取url中的code方法
      const url = location.search
      this.winUrl = url
      const theRequest = new Object()
      if (url.indexOf('?') != -1) {
        const str = url.substr(1)
        const strs = str.split('&')
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1]
        }
      }
      return theRequest
    }
  }
}
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
}
html,
body,
#app {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* background: #2ea188 url(~@/assets/background-3.webp) center center no-repeat;
  background-size: 100% 100%; */
  background: #207560 url(~@/assets/background-3.webp) center top no-repeat;
  background-size: contain;
  z-index: -1;
}
.transparent::before {
  /* background: #368b62; */

  background-image: linear-gradient(to bottom, #207560 55%, #0b2c00);
  /* filter: blur(5px); */
}
</style>
