import Vue from 'vue'
import Router from 'vue-router'
import storage from 'store'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('./view/index/index'),
    children: [
      {
        path: '/index/form',
        component: () => import('./view/home/modules/form')
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('./view/home/index'),
    children: [
      {
        path: '/home/form',
        component: () => import('./view/home/modules/form')
      }
    ]
  },
  // {
  //   path: '/home/video',
  //   component: () => import('./view/home/modules/video')
  // },
  {
    path: '/home/rule',
    component: () => import('./view/home/modules/rule')
  },
  {
    path: '/home/rank',
    component: () => import('./view/home/modules/rank')
  },
  {
    path: '/home/record',
    component: () => import('./view/home/modules/record')
  },
  {
    path: '/count-down',
    component: () => import('./view/countDown/index')
  },
  {
    path: '/question',
    component: () => import('./view/question/index')
  },
  {
    path: '/result',
    component: () => import('./view/result/index')
  },
  {
    path: '/lucky',
    component: () => import('./view/lucky/index')
  },
  {
    path: '/clear-cache',
    component: () => import('./view/operate/index')
  }
]

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '')
})

const router = new Router({ scrollBehavior: () => ({ y: 0 }), routes })

router.beforeEach((to, from, next) => {
  // const title = to.meta && to.meta.title
  // if (title) {
  //   document.title = title
  // }
  if (storage.get('ANSWER_OPENID')) {
    next()
  } else {
    if (['index'].includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: '/index' })
    }
  }
})

export { router }
